<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import VueHtml2pdf from 'vue-html2pdf'

	export default {
		components: {
        	VueHtml2pdf
    	},
		extends: BaseVue,
		data() {
			return {
				data: {},
				cat: {},
				brand: {},
				brandLogo: '',
				contact: {},
				text: {},
				country: {},
				region: {},
				scale: {},
				body: {},
				input: {
					start: 5
				},
				start1: 0,
				start2: 0,
				start3: 0,
				start4: 0,
				start5: 0,
				count_all: 0,
				review: [],
				filter: {
					sort: 'new'
				},
				Pagination: {},
				grape: '',
				timestamp: '',
				btnPDFID:{},
				btnPDFEN:{},
				htmlToPdfOptions : {
					enableLinks: true,
					image: {
						type: 'jpeg', 
						quality: 1
					},
					html2canvas: {
						scale: 1,
						useCORS: true
					},

					jsPDF: {
						unit: 'in',
						format: 'a4',
						orientation: 'portrait'
					}
				}
			}
		},
		computed: {
			mySlug() {
				return this.$route.params.slug
			}
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.$set(this.$root, 'page', this);
			this.apiGetDetail()
			this.timestamp = this.printTimestamp();
		},
		watch: {
			'$route.query'(v) {
				this.apiGetDetail()
			},
			'filter.sort'(v) {
				this.$router.push({
					path: this.$route.path,
					query: Object.assign(Gen.clone(this.$route.query), {
						sort: v
					})
				})
			}
		},
		methods: {
			generateReport () {
				this.htmlToPdfOptions.filename = this.data.apd_name+'-'+this.timestamp
	            this.$refs.html2Pdf.generatePdf()
	        },
	        printTimestamp: function () {
	            return Date.now();
	         },
			apiGetDetail() {
				Gen.apirest("/detail-wine/" + this.mySlug, Gen.clone(this.$route.query), (err, resp) => {
					if (err) console.log(err)
					if (resp.code == 404) return this.$router.push({
						name: '404'
					})
					this.data = resp.data
					this.cat = resp.cat
					this.contact = resp.contact
					this.text = resp.text
					this.brand = resp.brand
					this.brandLogo = resp.brandLogo
					this.country = resp.country
					this.region = resp.region
					this.body = resp.body
					this.scale = resp.dry
					this.start1 = resp.star1
					this.start2 = resp.star2
					this.start3 = resp.star3
					this.start4 = resp.star4
					this.start5 = resp.star5
					this.count_all = resp.count_all
					this.review = resp.review.data
					this.Pagination = resp.review
					this.grape = resp.grape
					this.btnPDFID = resp.btnPDFID
					this.btnPDFEN = resp.btnPDFEN

					setTimeout(() => {
						SEMICOLON.widget.init()
					}, 500)
					if (this.$route.query.sort) {
						setTimeout(() => {
							$('[href="#tabs-34"]').click()
						}, 1000)
					}
					if (this.$route.query.page > 0) {
						setTimeout(() => {
							$('[href="#tabs-34"]').click()
						}, 2000)
					}
				})
			},
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				this.input.id_prod = this.data.apd_id
				this.input.id_user = this.user.u_id
				Gen.apirest('/review', this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return swal(err.resp.message, "", "warning")
					this.input = {}
					swal(resp.message, "", "success")
					this.apiGetDetail()
				}, "POST")
			},
			addReview() {
				if (!this.user) return this.$router.push({
					name: 'Login'
				})
				this.input.start = this.input.start
			},
			onPaging(page = 1) {
				if ($(".page-item.active").text() == page) return
				var query = Object.assign(Gen.clone(this.$route.query), {
					page: page
				})
				this.$router.push({
					name: this.$route.name,
					query: query
				})
			}
		},
	};
</script>
<template>

	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row">
						<div class="col-md-7">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link :to="{name:'Home'}">Home</router-link>
								</li>
								<li v-if="cat.mpc_id==1" class="breadcrumb-item">
									<router-link :to="{name:'Product'}">{{cat['mpc_name_'+hl]}}</router-link>
								</li>
								<li v-if="cat.mpc_id==2" class="breadcrumb-item">
									<router-link :to="{name:'Product2'}">{{cat['mpc_name_'+hl]}}</router-link>
								</li>
								<li v-if="cat.mpc_id==3" class="breadcrumb-item">
									<router-link :to="{name:'Product3'}">{{cat['mpc_name_'+hl]}}</router-link>
								</li>
								<li v-if="cat.mpc_id==4" class="breadcrumb-item">
									<router-link :to="{name:'Product4'}">{{cat['mpc_name_'+hl]}}</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">{{data.apd_name}}</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-4 col-md-6">
							<div class="wrap_prod_list">
								<div class="wrap_prod_thumb">
									<router-link :to="{name:'DetailProduct',params:{slug:data.slug}}">
										<img id="myimage" :src="uploader(data.apd_image)" :alt="data.apd_name" />
									</router-link>
									<a href="javascript:;" :id="'fav_'+data.apd_id" @click="addFavorite(data)" class="btn_fav">
										<i v-if="data.isFavorite=='Y'" class="icon-heart3"></i>
										<i v-else class="icon-line-heart"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="product_detail_desc">
								<h1>{{data.apd_name}}</h1>
								<div class="row">
									<div class="col-md-5 col-6">
										<div class="rating_prod_detail">
											<i :class="data.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
											<i :class="data.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
											<i
												:class="data.apd_review_start>2 ? (data.apd_review_start > 2 && data.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
											<i
												:class="data.apd_review_start>3 ? (data.apd_review_start > 3 && data.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
											<i
												:class="data.apd_review_start>4 ? (data.apd_review_start > 4 && data.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
										</div>
									</div>
									<div class="col-md-7 col-6 padleft_0">
										<div class="result_review">
											<h3>{{data.apd_review_start}} <a v-if="data.apd_review_count > 0"
													href="">({{data.apd_review_count}}
													{{data.apd_review_count == 1 ?web.lbl_review : web.lbl_reviews}})</a></h3>
										</div>
									</div>
								</div>
								<p>
									{{brand ? brand :''}} {{data.grape ? ' / '+data.grape:''}}
									{{data.apd_avb? ' / '+data.apd_avb+'% ABV ':''}} {{country ? '/ '+country: ''}}
									{{region ? '/ '+region: ''}}
								</p>
								<h3>{{'Rp '+ data.price }}</h3>
							</div>
							<div class="clearfix"></div>
							<div class="row">
								<div class="col-md-10">
									<a v-if="data.apd_how_to_bay == 'W'" target="_blank" :href="'https://wa.me/'+contact+'?text='+text"
										class="btn_buy d-md-inline-block">{{web.check_avaibility}}</a>
									<a v-if="data.apd_how_to_bay == 'L'" target="_blank" :href="data.apd_link"
										class="btn_buy d-md-inline-block">{{web.lbl_by_product}}</a>
								</div>
								<!--<div class="col-md-2">
									<button class="btn_buy d-md-inline-block" @click="generateReport()"><i class="icon-file-pdf"></i></button>
								</div>-->
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabs tabs-bb clearfix" id="tab-9">
								<ul class="tab-nav clearfix">
									<li><a href="#tabs-33">{{web.lbl_product_detail}}</a></li>
									<li><a href="#tabs-34">{{web.lbl_reviews}}</a></li>
									<li @click="generateReport()"><a class="col_link_pdf"><i class="icon-file-pdf"></i>&nbsp;{{hl == 'id' ? btnPDFID.aws_val : btnPDFEN.aws_val }}</a></li>
								</ul>
								<div class="tab-container">
									<div class="tab-content clearfix" id="tabs-33">
										<div class="row">
											<div class="col-lg-8">
												<div v-if="brand" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_brand}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{brand}}</p>
														</div>
													</div>
												</div>
												<div v-if="data.apd_year" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_year}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{data.apd_year}}</p>
														</div>
													</div>
												</div>
												<div v-if="data.grape" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_grape}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{data.grape}}</p>
														</div>
													</div>
												</div>


												<div v-if="data.apd_avb" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_abv}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{data.apd_avb?data.apd_avb+'% ABV':''}}</p>
														</div>
													</div>
												</div>
												<div v-if="scale" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_sweet}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{scale}}</p>
														</div>
													</div>
												</div>
												<div v-if="body" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_body}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{body}}</p>
														</div>
													</div>
												</div>
												<div v-if="data.apd_pairing" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_pairing}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{data.apd_pairing}}
															</p>
														</div>
													</div>
												</div>
												<div v-if="data.apd_note" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_note}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{data.apd_note}}
															</p>
														</div>
													</div>
												</div>

												<div v-if="country" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_country}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{country}}</p>
														</div>
													</div>
												</div>

												<div v-if="region" class="list_detail_prod">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_region}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{region}}</p>
														</div>
													</div>
												</div>
												
												<div v-if="grape" class="list_detail_prod no-border-bottom">
													<div class="row">
														<div class="col-md-4 col-6">
															<h3>{{web.lbl_product_varietals}}</h3>
														</div>
														<div class="col-md-6 col-6">
															<p>{{grape}}</p>
														</div>
													</div>
												</div>

											</div>
											<!--
											<div class="col-lg-8 col-sm-12 col-xs-12 prod_note mt-4 ml-2">
												<h3>{{web.lbl_product_varietals}}</h3>
												<div v-html="data['apd_desc_'+hl]"></div>
											</div>
											-->
										</div>
									</div>
									<div class="tab-content clearfix" id="tabs-34">
										<div class="row">
											<div class="col-md-8" v-if="review.length">
												<ul class="skills">
													<li :data-percent="start5 > 0 ? ((start5/count_all)*100) : 0">
														<span>5</span>
														<div class="progress">
															<div class="progress-percent">
																<div class="counter counter-inherit counter-instant"><span data-from="0"
																		:data-to="start5" data-refresh-interval="30" data-speed="1100"></span></div>
															</div>
														</div>
													</li>
													<li :data-percent="start4 > 0 ? ((start4/count_all)*100) : 0">
														<span>4</span>
														<div class="progress">
															<div class="progress-percent">
																<div class="counter counter-inherit counter-instant"><span data-from="0"
																		:data-to="start4" data-refresh-interval="30" data-speed="1100"></span></div>
															</div>
														</div>
													</li>
													<li :data-percent="start3 > 0 ? ((start3/count_all)*100) : 0">
														<span>3</span>
														<div class="progress">
															<div class="progress-percent">
																<div class="counter counter-inherit counter-instant"><span data-from="0"
																		:data-to="start3" data-refresh-interval="30" data-speed="1100"></span></div>
															</div>
														</div>
													</li>
													<li :data-percent="start2 > 0 ? ((start2/count_all)*100) : 0">
														<span>2</span>
														<div class="progress">
															<div class="progress-percent">
																<div class="counter counter-inherit counter-instant"><span data-from="0"
																		:data-to="start2" data-refresh-interval="30" data-speed="1100"></span></div>
															</div>
														</div>
													</li>
													<li :data-percent="start1 > 0 ? ((start1/count_all)*100) : 0">
														<span>1</span>
														<div class="progress">
															<div class="progress-percent">
																<div class="counter counter-inherit counter-instant"><span data-from="0"
																		:data-to="start1" data-refresh-interval="30" data-speed="1100"></span></div>
															</div>
														</div>
													</li>
												</ul>
											</div>
											<div class="col-lg-8 mt-3">
												<div class="row justify-content-end rv_martop">
													<div class="col-12" v-if="review.length < 1">
														<h4>{{web.lbl_add_review}}</h4>
													</div>
													<div class="col-md-8 mb-3 mb-md-0">
														<a class="btn_review" @click="addReview" data-toggle="collapse" href="#collapseExample"
															role="button" aria-expanded="false"
															aria-controls="collapseExample">{{web.btn_add_review}}</a>
													</div>

													<div class="col-md-4 col-6">
														<div class="form-group select_custom_dark" v-if="review.length">
															<select v-model="filter.sort" id="template-contactform-service"
																name="template-contactform-service" class="sm-form-control frm_custom_filter valid">
																<option value="new">{{web.lbl_newest}}</option>
																<option value="desc">{{web.lbl_highest}}</option>
																<option value="asc">{{web.lbl_lowes}}</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<VForm @resp="onSubmit" style="width: 100%;">
														<div class="col-md-12 collapse rv_martop" id="collapseExample">
															<div class="row">
																<div class="col-md-12">
																	<!-- Change starability-basic to different class to see animations. -->
																	<label>{{web.lbl_rating}}</label>
																	<fieldset class="starability-basic nomargin">
																		<input type="radio" v-model="input.start" id="no-rate" class="input-no-rate"
																			name="rating" value="0" checked aria-label="No rating." />
																		<input type="radio" v-model="input.start" id="rate1" name="rating" value="1" />
																		<label for="rate1">1 star.</label>
																		<input type="radio" v-model="input.start" id="rate2" name="rating" value="2" />
																		<label for="rate2">2 stars.</label>
																		<input type="radio" v-model="input.start" id="rate3" name="rating" value="3" />
																		<label for="rate3">3 stars.</label>
																		<input type="radio" v-model="input.start" id="rate4" name="rating" value="4" />
																		<label for="rate4">4 stars.</label>
																		<input type="radio" v-model="input.start" id="rate5" name="rating" value="5" />
																		<label for="rate5">5 stars.</label>
																		<span class="starability-focus-ring"></span>
																	</fieldset>
																</div>
																<div class="col-md-12 mb-2">
																	<div class="form-group">
																		<label>{{web.lbl_write_rating}}<span class="error">*</span></label>
																		<textarea name="review" v-model="input.review" maxlength="254" minlength="5"
																			required :title="web.lbl_write_rating"
																			class="frm_custom_txt_area h_auto form-control" rows="4"
																			:placeholder="web.lbl_write_rating"></textarea>
																	</div>
																</div>
																<div class="col-md-12 text-center">
																	<p><button type="submit" class="btn_line">{{web.btn_submit}} <i
																				class="icon-line-arrow-right"></i></button></p>
																</div>
															</div>
														</div>
													</VForm>
												</div>
												<element v-if="review.length">
													<div class="review_box_list mb-3" v-for="(r,k) in review" :key="k">
														<div class="row">
															<div class="col-md-8">
																<h3>{{r.au_full_name}}</h3>
																<p>
																	{{r.ar_message}}
																</p>
																<div class="rating_prod">
																	<i :class="r.ar_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
																	<i :class="r.ar_start>=2 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
																	<i :class="r.ar_start>=3 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
																	<i :class="r.ar_start>=4 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
																	<i :class="r.ar_start==5 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
																</div>
															</div>
															<div class="col-md-4">
																<p class="date_review">
																	{{r.dates}}
																</p>
															</div>
														</div>
													</div>
													<div class="row">
														<div class="col-12 justify-content-center">
															<Pagination class="justify-content-center mt-5" :data="Pagination" :limit="3"
																@pagination-change-page="onPaging"></Pagination>
														</div>
													</div>
												</element>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="wrap_logo_dimatique_foot">
								<img :src="assets('fo_images','logo_dimatique_pdf.png')" alt="Dimatique Logo" title="Dimatique Logo" />
								<a href="https://dimatique.com">
									www.dimatique.com
								</a>
							</div>
						</div>
					</div>
					<vue-html2pdf
				        :show-layout="false"
				        :float-layout="true"
				        :enable-download="true"
				        :preview-modal="false"
				        :paginate-elements-by-height="1400"
				        :filename="data.apd_name+'-'+timestamp"
					    :pdf-quality="2"
					    pdf-format="a4"
					    pdf-orientation="portrait"
					    pdf-content-width="800px"
					    pdf-content-height="982px"
					    :manual-pagination="false"
						:html-to-pdf-options="htmlToPdfOptions"
						@progress="onProgress($event)"
				        @hasStartedGeneration="hasStartedGeneration()"
				        @hasGenerated="hasGenerated($event)"
				        ref="html2Pdf"
				    >
				        <section slot="pdf-content" style="height: 982px; position:relative; margin:0px;  background-color: #fff;">
				        	<div class="row">
			        			<div class="col-md-12">
			        				<div class="wrap_brand_logo">
			        					<img :src="uploader(brandLogo)" :alt="data.apd_name" :title="data.apd_name" />
			        				</div>
			        			</div>
				        	</div>

				        	<div class="row">

				        		<div class="col-4 m_top_product">
				        		<div :style="'background-image: url(\''+uploader(data.apd_image)+'\');background-repeat:no-repeat;background-position-x: center; background-size:cover; height:550px;'"></div>
				        		</div>
				        		<div class="col-8">
				        			<div class="wrap_title_wine">
				        				<h3>{{data.apd_name}}</h3>
				        			</div>
				        			<div class="bor_bottom_title"></div>

				        			<div v-if="brand" class="wrap_wine_post">
				        				<h4>{{web.lbl_brand}}:&nbsp;<span>{{brand}}</span></h4>
				        			</div>

				        			<div v-if="country" class="wrap_wine_post">
				        				<h4>{{web.lbl_country}}:&nbsp;<span>{{country}}</span></h4>
				        			</div>

				        			<div v-if="region" class="wrap_wine_post">
				        				<h4>{{web.lbl_region}}:&nbsp;<span>{{region}}</span></h4>
				        			</div>

				        			<div v-if="grape" class="wrap_wine_post">
				        				<h4>{{web.lbl_product_varietals}}:&nbsp;<span style="margin:0;">{{grape}}</span></h4>
				        			</div>

				        			<div v-if="data.apd_year" class="wrap_wine_post">
				        				<h4>{{web.lbl_year}}:&nbsp;<span>{{data.apd_year}}</span></h4>
				        			</div>

				        			<div v-if="data.apd_avb" class="wrap_wine_post">
				        				<h4>{{web.lbl_abv}}:&nbsp;<span>{{data.apd_avb?data.apd_avb+'% ABV':''}}</span></h4>
				        			</div>

				        			<div v-if="scale" class="wrap_wine_post">
				        				<h4>{{web.lbl_sweet}}:&nbsp;<span>{{scale}}</span></h4>
				        			</div>

				        			<div v-if="body" class="wrap_wine_post">
				        				<h4>{{web.lbl_body}}:&nbsp;<span>{{body}}</span></h4>
				        			</div>

				        			<div v-if="data.apd_pairing" class="wrap_wine_post">
				        				<h4>{{web.lbl_pairing}}:&nbsp;<span style="margin:0;">{{data.apd_pairing}}</span></h4>
				        			</div>

				        			<div v-if="data.apd_note" class="wrap_wine_post">
				        				<h4>{{web.lbl_note}}:&nbsp;<span style="margin:0;">{{data.apd_note}}</span></h4>
				        			</div>

								</div>
				        	</div>

				        	<div class="row">
				        		<div class="col-md-12">
				        			<div class="wrap_logo_dimatique_foot" style="position:absolute;top:175px;left:43%;width:15%;z-index:10">
										<img :src="assets('fo_images','logo_dimatique_pdf.png')" alt="Dimatique Logo" title="Dimatique Logo" />
										<a href="https://dimatique.com">
											www.dimatique.com
										</a>
				        			</div>
				        		</div>
				        	</div>
				        </section>
				    </vue-html2pdf>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>